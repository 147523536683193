import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import TeamLogo from "../teams/Logo";
import "./TabelleBanner.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const TabelleBanner = (props) => {
  const teams = useSelector((state) => state.teams.teams);
  const team = teams.find((team) => team.id === props.team);
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;
  const navigate = useNavigate();

  return (
    <Col xs md className={isMobile ? "pt-2 ps-0 pe-1" : "pt-2"}>
      <div
        className="d-grid h-100"
        style={{
          //minHeight: "33vh",
          background: `linear-gradient(to bottom, transparent 0%, ${team.color} 50%)`,
          cursor: "pointer",
        }}
        onClick={() => navigate(`/team/${team.id}`)}
      >
        <img
          alt="Background"
          width="100%"
          src={require("../../images/Background/banner_1.jpg")}
          style={{
            opacity: "0.2",
            maskImage:
              "linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 25%, transparent 75%)",
          }}
        />
        <div
          className="justify-content-end d-flex"
          style={{ zIndex: "1", flexDirection: "column", margin: "0 auto" }}
        >
          <TeamLogo team={props.team} />
        </div>
        <div className="text-white mb-2 text-center align-self-end">
          {!isMobile && team.name}
        </div>
      </div>
    </Col>
  );
};

export default TabelleBanner;
