import { ADD_TABELLE } from "../constants/actionTypes";

const initialState = {
  teams: [
    {
      id: 1,
      name: "EHC Inter Bavaria",
      short: "Inter",
      erfolge: "",
      color: "#2f4a26",
    },
    {
      id: 2,
      name: "Eiskratzer Peißenberg e. V.",
      short: "Eiskratzer",
      erfolge: "",
      color: "#fe7d30",
    },
    {
      id: 3,
      name: "Landjugend Peißenberg",
      short: "Landjugend",
      erfolge: "",
      color: "#4633ff",
    },
    {
      id: 4,
      name: "EC Etting",
      short: "Etting",
      erfolge: "",
      color: "#3466fe",
    },
    {
      id: 5,
      name: "Hechendorf Anterer",
      short: "Anterer",
      erfolge: "",
      color: "#ffda3f",
    },
  ],
};

const Teams = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TABELLE:
      return { ...state, teams: action.payload };

    default:
      return state;
  }
};

export default Teams;
